:root {
  --space-0: 0;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 20px;
  --space-5: 32px;
  --space-6: 52px;
  --space-7: 84px;
  --space-8: 136px;
  --space-9: 220px;

  --font-family-sans: Roboto;
  --font-family-serif: PT Serif;
  --font-family-mono: IMB Plex Mono;

  --font-size-0: 12px;
  --font-size-1: 14px;
  --font-size-2: 16px;
  --font-size-3: 18px;
  --font-size-4: 20px;
  --font-size-5: 24px;
  --font-size-6: 30px;
  --font-size-7: 36px;
  --font-size-8: 48px;
  --font-size-9: 60px;
  --font-size-10: 72px;

  --line-height-0: 16px;
  --line-height-1: 20px;
  --line-height-2: 24px;
  --line-height-3: 28px;
  --line-height-4: 28px;
  --line-height-5: 32px;
  --line-height-6: 36px;
  --line-height-7: 40px;
  --line-height-8: 48px;
  --line-height-9: 60px;
  --line-height-10: 72px;
  --line-height-11: 96px;
  --line-height-12: 128px;

  --white: #fff;
  --black: #101112;
  --gray-200: #ced2d9;
  --gray-600: #6e7683;
  --blue-600: #1e61cd;
  --magenta-100: #f9d7eb;

  --max-width-0: 320px;
  --max-width-1: 768px;
}

html {
  font-family: var(--font-family-sans), var(--font-family-serif), sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  margin: 0;
  padding-left: 5rem;
  padding-right: 5rem;
  color: var(--white);
}

/* Post page */
.post {
  width: 100%;
  margin: var(--space-1) 0 var(--space-4);
}

.post .post__cover,
.post .post__cover--none {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.post .post__cover--none {
  background: var(--black);
}

.post .post__container {
  padding: 0 var(--space-3);
}

.post .post__content,
.post .post__attribution {
  font-family: var(--font-family-serif);
  font-weight: 400;
  font-size: var(--font-size-4);
  line-height: var(--line-height-5);
  letter-spacing: -0.02em;
  margin-top: var(--space-6);
  color: rgb(255, 255, 255, 0.5);

  /* Targeting tags in PortableText */
}

.post .post__content blockquote {
  border-left: 5px solid var(--black);
  padding-left: var(--space-3);
  margin-left: var(--space-4);
}

.post .post__content a,
.post .post__attribution a {
  color: var(--blue-600);
  text-decoration: none;
}

.post .post__title {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-7);
  line-height: var(--line-height-6);
  margin: var(--space-4) 0;
  font-weight: 800;
}

.post .post__description {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-5);
  line-height: var(--line-height-4);
  margin-top: 0;
  font-weight: 400;
}

.post .post__date {
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-family: var(--font-family-sans);
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  margin-top: var(--space-4);
  color: rgb(255, 255, 255, 0.5);
}

@media (min-width: 800px) {
  .post .post__cover,
  .post .post__cover--none {
    width: 750px;
    height: 380px;
  }

  .post .post__title {
    font-size: var(--font-size-10);
    line-height: var(--line-height-10);
    margin: var(--space-6) 0 0;
    letter-spacing: -0.025em;
  }

  .post .post__description {
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
    margin-top: var(--space-3);
    margin-bottom: var(--space-3);
  }

  .post .post__date,
  .post .post__author {
    font-size: var(--font-size-3);
    line-height: var(--line-height-2);
    margin-top: var(--space-0);
  }

  .post .post__content {
    margin-top: var(--space-7);
  }
}

/* Layout */
.container {
  margin: 0 auto;
}

main {
  margin-top: 45px;
}

.header {
  display: flex;
  padding: 0 var(--space-1);
  border-bottom: 1px solid #ced2d9;

  z-index: 10;
  background: var(--white);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.header .header__title {
  font-weight: 800;
  font-size: var(--font-size-3);
  line-height: var(--line-height-1);
  padding-left: var(--space-2);
  margin: var(--space-3) 0;
  text-decoration: none;
  color: var(--black);
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--space-3);
}

.footer .footer__text {
  font-size: var(--font-size-1);
  line-height: var(--line-height-1);
  display: flex;
  align-items: center;
  gap: 2px;
}

@media (min-width: 575px) {
  .container {
    max-width: var(--max-width-1);
    padding: 0 var(--space-4);
  }

  main {
    margin-top: unset;
  }

  .header {
    position: unset;
    border-bottom: none;
    margin: var(--space-3) 0;
    padding: var(--space-2) 0;
    background: unset;
  }

  .header .header__title {
    margin: var(--space-3) 0 var(--space-2);
    font-size: var(--font-size-5);
  }

  .footer {
    margin: var(--space-3) 0;
  }
}

/* Welcome component */

.welcome__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-2) var(--space-2);
}

.logos {
  display: none;
}

.steps {
  flex-direction: column;
  width: 100%;
}

.steps .steps__list {
  list-style-type: none;
  padding: 0;
}

.steps .steps__entry {
  margin-bottom: var(--space-4);
}

.steps .steps__title {
  font-size: var(--font-size-5);
  line-height: var(--line-height-2);
}

.steps .steps__subtitle {
  font-size: var(--font-size-3);
  line-height: var(--line-height-2);
}

.steps .steps__text {
  font-family: var(--font-family-serif);
  line-height: var(--line-height-2);
}

.steps .steps__text a {
  color: var(--blue-600);
  text-decoration: none;
}

@media (min-width: 575px) {
  .welcome__container {
    width: 100%;
  }

  .logos {
    display: flex;
    align-items: center;
    margin: var(--space-6) 0 var(--space-5) 0;
  }

  .logos .logos__blur {
    display: flex;
    position: absolute;
    width: 375px;
    height: 115px;
    background: var(--magenta-100);
    filter: blur(82px);
    transform: rotate(-19deg);
    z-index: -1;
  }

  .logos .logos__plus {
    display: flex;
    font-family: var(--font-family-sans);
    font-weight: 800;
    font-size: var(--font-size-7);
    line-height: var(--line-height-6);
    margin: 0 var(--space-4);
  }

  .logos .logos__entry {
    display: flex;
  }

  .steps {
    max-width: var(--max-width-0);
    display: flex;
    padding: 0;
  }

  .steps .steps__subtitle {
    margin-top: var(--space-4);
  }

  .steps .steps__list {
    margin-top: 0;
  }
}

/* Card */

.card {
  display: flex;
  flex-direction: column;
  padding: var(--space-2);
  padding: 9px;
  position: relative;
  border-bottom: 1px solid rgb(81, 81, 81);
  background-color: rgb(31, 32, 39);
  border-radius: 6px;
}

.card .card__container {
  margin: 0 var(--space-1) 0;
}

.card .card__cover {
  width: 100%;
  height: 231px;
  -o-object-fit: cover;
  object-fit: cover;
}

.card .card__cover--none {
  width: 100%;
  height: 231px;
  background: var(--black);
}

.card .card__title {
  font-family: var(--font-family-sans);
  font-weight: 800;
  font-size: var(--font-size-7);
  line-height: var(--line-height-6);
  letter-spacing: -0.025em;
  margin: var(--space-3) 0;
}

.card .card__description {
  font-family: var(--font-family-serif);
  font-weight: 400;
  font-size: var(--font-size-4);
  line-height: var(--line-height-3);
  margin-top: 0;
  color: rgb(255, 255, 255, 0.5);
}

.card .card__date {
  font-weight: 600;
  font-family: var(--font-family-sans);
  font-size: var(--font-size-1);
  margin-top: calc(var(----space-4) + 7);
  color: rgb(255, 255, 255, 0.5);
}

.card .card__categories .MuiChip-root {
  color: rgba(255, 255, 255, 0.95);
  background-color: rgba(255, 255, 255, 0.16);
}

.card .card__link {
  text-decoration: none;
  color: var(--white);
}

.card .card__link:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.card .card__link::before {
  content: '';
  position: absolute;
  inset: 0;
}

.card:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (min-width: 575px) {
  .card {
    border: 1px solid rgb(81, 81, 81);
  }

  .card .card__title {
    margin-top: var(--space-4);
  }

  .card:last-child {
    border-bottom: 1px solid rgb(81, 81, 81);
  }
}

@media (min-width: 800px) {
  .card {
    flex-direction: row;
  }

  .card .card__container {
    margin: 0 var(--space-4) 0;
  }

  .card .card__cover,
  .card .card__cover--none {
    min-width: 366.5px;
    max-width: 366.5px;
    max-height: 231px;
  }
}

.EasyMDEContainer {
  background-color: white;
}
